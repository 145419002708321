import { createSlice } from '@reduxjs/toolkit';

import { IFullBite, ILeanBite } from '../../types/bite';
import { IRootState } from '../../types/store';
import { mergeLeanAndFullCollections } from '../../utils/collections';

const bitesSlice = createSlice({
  name: 'bites',
  initialState: getInitialState(),
  extraReducers: getExtraReducers(),
  reducers: getReducers(),
});

export const { fetchBites, fetchLeanBitesSuccess, fetchFullBitesSuccess, fetchBitesError } = bitesSlice.actions;

export default bitesSlice.reducer;

export const selectBites = (state: IRootState) => state.bites;

function getInitialState() {
  return {
    isFetching: false,
    error: '',
    bites: [] as (ILeanBite | IFullBite)[],
  };
}

type IBitesState = ReturnType<typeof getInitialState>;

function getExtraReducers() {
  return {
    'auth/logout': () => getInitialState(),
  };
}
function getReducers() {
  return {
    fetchBites: (state: IBitesState) => {
      state.isFetching = true;
      state.error = '';
      state.bites = [];
    },
    fetchLeanBitesSuccess: (state: IBitesState, action) => {
      const leanBites: ILeanBite[] = action.payload;
      state.isFetching = false;
      state.bites = mergeLeanAndFullCollections<ILeanBite, ILeanBite | IFullBite>(leanBites, state.bites);
    },
    fetchFullBitesSuccess: (state: IBitesState, action) => {
      const fullBites: IFullBite[] = action.payload;
      state.isFetching = false;
      state.bites = mergeLeanAndFullCollections<ILeanBite | IFullBite, IFullBite>(state.bites, fullBites);
    },
    fetchBitesError: (state: IBitesState, action) => {
      state.isFetching = false;
      state.error = action.payload;
    },
  };
}
