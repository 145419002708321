import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';

import BitesLogo from '../../../../assets/images/bite-logo.png';
import Button from '../../../Buttons/Button';

type TMessageEmailPreviewProps = {
  shareableTitle: string;
  shareableCoverUrl: string;
};

const MessageEmailPreview: React.FC<TMessageEmailPreviewProps> = ({ shareableCoverUrl, shareableTitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.emailPreviewCallToAction}>
      <div className={classes.emailPreviewContent}>Hi John Doe,</div>
      <div className={classes.emailPreviewContent}>
        Don’t miss the new Bite <i>{shareableTitle}</i>.
      </div>
      <div className={classNames(classes.emailPreviewCallToAction, classes.contentBottom)}>
        Leave a comment and let us know what you think TOO!
      </div>
      <div className={classNames(classes.emailPreviewCallToAction, classes.contentBottom)}>
        <img className={classes.emailCoverImage} src={shareableCoverUrl ?? BitesLogo} alt='logo' />
      </div>
      <div>
        <Button width='fit-content' className={classes.emailPreviewButton} isBigText={false} height={22} disabled>
          Click to watch now!
        </Button>
      </div>
    </div>
  );
};

export default MessageEmailPreview;

const useStyles = makeStyles({
  emailPreviewContent: {
    paddingBottom: '2px',
  },
  emailPreviewCallToAction: {
    margin: '16px 0',
  },
  emailCoverImage: {
    maxHeight: '125px',
    maxWidth: '75%',
  },
  emailPreviewButton: {
    padding: '2px 6px',
    margin: 'auto',
  },
  contentBottom: {
    display: 'flex',
    justifyContent: 'center',
  },
});
