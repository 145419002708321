import { TypeColumns } from '@inovua/reactdatagrid-community/types/TypeColumn';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import React, { useCallback, useMemo, useState } from 'react';

import { REACT_DATA_GRID_LICENSE_KEY } from '../../../constants/constants';
import useIsMobile from '../../../hooks/useIsMobile';
import { IRdgColumnVisibility, IRdgFilters } from '../../../types/reactDataGrid';
import { getScrollProps } from '../../../utils/reactDataGrid';
import TableControls from '../TableControls';
import useCheckbox from '../useCheckbox';
import useTableUsers from '../useTableUsers';

import useColumns from './useColumns';
import useFormattedUsers from './useFormattedUsers';

interface IProps {
  onToggleColumnVisibility: (columnName: string) => void;
  columnsVisibility: IRdgColumnVisibility;
  rawData: any[];
  rawColumns?: TypeColumns;
  filterValue: IRdgFilters;
  onFilterValueChange: (nextFilterValue: IRdgFilters) => void;
  makeFooterRows?: (data: any[]) => any[];
  groupBy?: string[];
  onGroupByChange?: (nextGroupBy: string[]) => void;
  maxVisibleColumnsOnMobile?: number;
  withCheckbox?: boolean;
  onSelectedRowsChange?: (nextSelectedRows: Record<any, any>) => void;
  onSend?: () => void;
  onSearch?: (string) => void;
  sendIconDisabledMessage?: string;
  sortable?: boolean;
  columns?: TypeColumns;
  origin: string;
  isShared?: boolean;
  getExportData?: (dataSource: any[] | ((prop: any) => any)) => void;
}

function ReactDataGridUserTable({
  onToggleColumnVisibility,
  columnsVisibility,
  rawData,
  rawColumns = [],
  filterValue,
  onFilterValueChange,
  makeFooterRows,
  groupBy,
  onGroupByChange,
  maxVisibleColumnsOnMobile,
  withCheckbox,
  onSelectedRowsChange,
  onSend,
  sendIconDisabledMessage,
  sortable = false,
  origin,
  onSearch,
  isShared,
  getExportData,
  ...restOfProps
}: IProps) {
  const isMobile = useIsMobile();
  const [gridRef, setGridRef] = useState(null);
  const [searchText, setSearchText] = useState('');

  const formattedUsers = useFormattedUsers({ rawData });
  const columns = useColumns({ rawColumns, columnsVisibility });
  const dataSource = useTableUsers({
    searchText,
    users: formattedUsers,
    columns,
    filterValue,
  });
  const { checkboxColumn, selectedRows } = useCheckbox({
    dataSource,
    withCheckbox,
    isShared,
    onSelectedRowsChange,
  });

  const footerRows = useMemo(() => {
    if (!makeFooterRows) {
      return [];
    }
    return makeFooterRows(dataSource);
  }, [makeFooterRows, dataSource]);

  const groupToString = (value) => ([null, undefined, ''].includes(value) ? 'Empty' : value);

  const renderGroupValue = useCallback(({ value, data }) => `${value} (${data.array.length})`, []);

  const handleSearch = (term) => {
    setSearchText(term);
    onSearch(term);
  };

  return (
    <>
      <TableControls
        searchText={searchText}
        onSearchTextChange={handleSearch}
        dataSource={dataSource}
        gridRef={gridRef}
        columnsVisibility={columnsVisibility}
        maxVisibleColumnsOnMobile={maxVisibleColumnsOnMobile}
        onToggleColumnVisibility={onToggleColumnVisibility}
        columns={columns}
        sendIconDisabledMessage={sendIconDisabledMessage}
        onSend={onSend}
        origin={origin}
        getExportData={getExportData}
      />
      <ReactDataGrid
        onReady={(ref) => {
          ref.current.collapseAllGroups();
          setGridRef(ref);
        }}
        scrollProps={getScrollProps()}
        enableFiltering
        groupColumn={{ renderGroupValue }}
        filterValue={filterValue}
        onFilterValueChange={onFilterValueChange}
        style={{ minHeight: window.innerHeight - 200 }}
        dataSource={dataSource}
        columns={columns}
        groupBy={!isMobile && groupBy}
        onGroupByChange={onGroupByChange}
        licenseKey={REACT_DATA_GRID_LICENSE_KEY}
        footerRows={footerRows}
        groupToString={groupToString}
        sortable={sortable}
        checkboxColumn={checkboxColumn}
        selected={selectedRows}
        checkboxOnlyRowSelect
        {...restOfProps}
      />
    </>
  );
}

export default ReactDataGridUserTable;
