import { AxiosRequestConfig } from 'axios';

import bitesApi, { IRequestProps } from '../services/bitesApi';
import { EIntegrations } from '../types/integration';

export const shareWithIntegration = (
  body: IShareWithIntegrationBody,
  requestConfig?: AxiosRequestConfig,
  requestProps?: IRequestProps,
) => bitesApi.post('/distribution/share/', body, requestConfig, requestProps);
export const scheduleDistributionAllUsers = (body: IScheduledDistributionBody) =>
  bitesApi.post('/distribution/schedule/', { ...body, unviewedUsersOnly: false });
export const scheduleDistributionUnviewedUsers = (body: IScheduledDistributionBody) =>
  bitesApi.post('/distribution/schedule/', { ...body, unviewedUsersOnly: true });

export const getWhatsAppTemplates = (organizationId: number) => {
  return bitesApi.get<TShareTemplates>(`/distribution/whatsapp/templates/?organization_id=${organizationId}`);
};

export const getSmsTemplates = (organizationId: number) => {
  return bitesApi.get<TShareTemplates>(`/distribution/sms/templates/?organization_id=${organizationId}`);
};

export type IShareWithIntegrationBody =
  | IShareWithTeamsBody
  | IShareWithWhatsappBody
  | IShareWithSMSBody
  | IShareWithMailMergeBody
  | IShareWithEmailBody
  | IShareWithBeekeeperBody;

export interface IShareWithTeamsBody extends IShareWithIntegrationBase {
  channel: number | null;
  integration: EIntegrations.Teams;
}

export interface IShareWithWhatsappBody extends IShareWithIntegrationBase {
  integration: EIntegrations.WhatsApp;
  template_id?: number;
  data1?: number[];
  data2?: number[];
  data3?: number[];
  data4?: number[];
  data5?: number[];
  data6?: number[];
  data7?: number[];
  data8?: number[];
}

export interface IShareWithSMSBody extends IShareWithIntegrationBase {
  integration: EIntegrations.SMS;
  template_id: number;
}

export interface IShareWithMailMergeBody extends IShareWithIntegrationBase {
  integration: EIntegrations.Mail_merge;
}

export interface IShareWithEmailBody extends IShareWithIntegrationBase {
  integration: EIntegrations.Email;
}

export interface IShareWithBeekeeperBody extends IShareWithIntegrationBase {
  integration: EIntegrations.Beekeeper;
  customReplyMessage: string;
}

interface IShareWithIntegrationBase {
  customReplyMessage?: string;
  users: Array<number | string> | null;
  biteShare?: number;
  playlist?: number;
}

// Note: currently scheduled distributions only work with an explicit list of users,
// rather than filters based on data1 - 8
type ScheduledDistParams = Omit<
  IShareWithWhatsappBody | IShareWithSMSBody | IShareWithMailMergeBody | IShareWithEmailBody | IShareWithBeekeeperBody,
  'data1' | 'data2' | 'data3' | 'data4' | 'data5' | 'data6' | 'data7' | 'data8'
> & { users: Array<number | string> };

interface IScheduledDistributionBody {
  sendAt: string; // ISO DateTime String
  distParams: ScheduledDistParams;
}

export type TShareTemplates = { id: string; name: string; text: string; is_reminder: boolean }[];
