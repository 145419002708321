import { Checkbox } from '@material-ui/core';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface IArgs {
  dataSource: any[];
  withCheckbox: boolean;
  isShared?: boolean;
  onSelectedRowsChange?: (nextSelectedRows: any) => void;
}

const useCheckbox = ({ dataSource, onSelectedRowsChange, isShared, withCheckbox }: IArgs) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState({});

  const emptyText = useMemo(() => t('common.empty'), [t]);

  const getFilteredUsersIdsUnderGroup = useCallback(
    ({ fieldPath, keyPath }) =>
      dataSource
        .filter((u) =>
          fieldPath?.every((data, index) =>
            keyPath[index] === emptyText ? [undefined, '', null].includes(u[data]) : u[data] === keyPath[index],
          ),
        )
        .map((u) => u.id),
    [emptyText, dataSource],
  );

  const areAllFilteredUsersUnderGroupSelected = ({ filteredUsersIdsUnderGroup }) =>
    filteredUsersIdsUnderGroup?.every((uid) => uid in selectedRows);

  const arePartFilteredUsersUnderGroupSelected = ({ filteredUsersIdsUnderGroup }) =>
    filteredUsersIdsUnderGroup?.some((uid) => uid in selectedRows);

  const toggleCheckboxGroup = ({ fieldPath, keyPath }) => {
    const filteredUsersIdsUnderGroup = getFilteredUsersIdsUnderGroup({
      fieldPath,
      keyPath,
    });
    const areAllSelected = areAllFilteredUsersUnderGroupSelected({
      filteredUsersIdsUnderGroup,
    });
    const nextSelectedRows = { ...selectedRows };
    filteredUsersIdsUnderGroup.forEach((uid) => {
      if (areAllSelected) {
        delete nextSelectedRows[uid];
      } else {
        nextSelectedRows[uid] = uid;
      }
    });
    setSelectedRows(nextSelectedRows);
    onSelectedRowsChange?.(nextSelectedRows);
  };

  useEffect(() => {
    setSelectedRows({});
  }, [isShared]);

  const toggleCheckboxItem = useCallback(
    (userId) => {
      const nextSelectedRows = { ...selectedRows };
      if (nextSelectedRows[userId]) {
        delete nextSelectedRows[userId];
      } else {
        nextSelectedRows[userId] = userId;
      }
      setSelectedRows(nextSelectedRows);
      onSelectedRowsChange?.(nextSelectedRows);
    },
    [selectedRows, onSelectedRowsChange],
  );

  const CheckboxColumn = ({ data }) => {
    const { id, __group, fieldPath, keyPath } = data;
    return (
      <Checkbox
        color='primary'
        style={{ padding: 0 }}
        checked={
          __group
            ? areAllFilteredUsersUnderGroupSelected({
                filteredUsersIdsUnderGroup: getFilteredUsersIdsUnderGroup({
                  fieldPath,
                  keyPath,
                }),
              })
            : !!selectedRows[id]
        }
        indeterminate={
          arePartFilteredUsersUnderGroupSelected({
            filteredUsersIdsUnderGroup: getFilteredUsersIdsUnderGroup({
              fieldPath,
              keyPath,
            }),
          }) &&
          !areAllFilteredUsersUnderGroupSelected({
            filteredUsersIdsUnderGroup: getFilteredUsersIdsUnderGroup({
              fieldPath,
              keyPath,
            }),
          })
        }
        onChange={() => (__group ? toggleCheckboxGroup({ fieldPath, keyPath }) : toggleCheckboxItem(id))}
      />
    );
  };

  const CheckboxHeader = ({ onChange, checked }) => (
    <Checkbox style={{ padding: 0, color: '#fff' }} color='primary' checked={checked} onChange={onChange} />
  );

  const checkboxColumn = withCheckbox
    ? {
        render: CheckboxColumn,
        renderCheckbox: CheckboxHeader,
      }
    : null;

  return { checkboxColumn, selectedRows };
};

export default useCheckbox;
