import { TypeColumns } from '@inovua/reactdatagrid-community/types/TypeColumn';
import sumBy from 'lodash/sumBy';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import EllipsisWithTooltip from '../../../../components/EllipsisWithTooltip';
import StyledPercentage from '../../../../components/StyledPercentage';
import { IFullPlaylist } from '../../../../types/playlist';
import { formatDateToRelativeTime } from '../../../../utils/dates';
import { percentage } from '../../../../utils/numbers';
import { selectPlaylist } from '../../playlist.slice';
const useRawColumns = (): TypeColumns => {
  const { totalQuestions } = useSelector(selectPlaylist);

  return useMemo(() => {
    const EllipsisColumn = ({ value }) => <EllipsisWithTooltip text={value} />;
    const Bites = ({
      value,
      data,
    }: {
      value: number;
      data: { array: IFullPlaylist[]; __group: boolean; total: number };
    }) => {
      if (data.__group) {
        const count = sumBy(data.array, (item) => Number(item.watched));
        const total = sumBy(data.array, 'total');
        return <StyledPercentage percentage={percentage(count, total)} />;
      }
      return `${value}/${data.total}`;
    };
    const Answered = ({ value, data }: { value: number; data: { array: IFullPlaylist[]; __group: boolean } }) => {
      if (data.__group) {
        const count = sumBy(data.array, (item) => Number(item.answered));
        const total = data.array.length * totalQuestions;
        return <StyledPercentage percentage={percentage(count, total)} />;
      }
      return `${value}/${totalQuestions}`;
    };
    const AnsweredCorrectly = ({
      value,
      data,
    }: {
      value: any;
      data: { array: IFullPlaylist[]; __group: any; answered: number };
    }) => {
      if (data.__group) {
        const count = sumBy(data.array, (item) => (item.answeredCorrectly ? Number(item.answeredCorrectly) : 0));
        const total = sumBy(data.array, (item) => Number(item.answered));
        return <StyledPercentage percentage={percentage(count, total)} />;
      }
      return `${value}/${data.answered}`;
    };

    return [
      {
        name: 'email',
        maxWidth: 100,
        header: 'Email',
        render: EllipsisColumn,
      },
      {
        name: 'phone',
        maxWidth: 100,
        header: 'Phone',
        render: EllipsisColumn,
      },
      { name: 'watched', header: 'Bites Opened', maxWidth: 100, render: Bites },
      {
        name: 'answered',
        header: 'Questions Answered',
        maxWidth: 150,
        render: Answered,
      },
      {
        name: 'answeredCorrectly',
        header: 'Success',
        maxWidth: 100,
        render: AnsweredCorrectly,
      },
      {
        name: 'lastVisit',
        maxWidth: 100,
        header: 'Last View Date',
        render: ({ value }) => {
          return value ? formatDateToRelativeTime(value) : '';
        },
      },
      {
        name: 'employeeId',
        maxWidth: 100,
        header: 'Employee Id',
        render: EllipsisColumn,
      },
    ];
  }, [totalQuestions]);
};

export default useRawColumns;
