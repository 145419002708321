import { Hidden } from '@material-ui/core';
import React, { useEffect } from 'react';
import { JSX } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import ErrorMsg from '../../components/ErrorMsg';
import Loader from '../../components/Loader';
import MainScreen from '../../components/MainScreen';
import MainScreenCard from '../../components/MainScreenCard';
import QuizSelectionTable from '../../components/QuizSelectionTable';
import ShareButtonAndManager from '../../components/Share/ShareButtonAndManager';
import { useShareLink } from '../../components/Share/useShareLink';
import { useFilterOutGuestsFromViewsTableUser } from '../../utils/useUsers';
import QuizScreen from '../quiz/Quiz.screen';
import { selectQuiz } from '../quiz/quiz.slice';
import { ContentType } from '../userAnalysis/components/Table/Table';

import { fetchQuizzes, selectQuizzes } from './quizzes.slice';

function QuizzesScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isFetching, error, quizzes } = useSelector(selectQuizzes);
  const { name, id, quizViewsByUser } = useSelector(selectQuiz);
  const shareablePath = `quiz/${id}`;

  useEffect(() => {
    dispatch(fetchQuizzes());
  }, [dispatch]);

  const renderTable = (): JSX.Element => {
    if (error) {
      return <ErrorMsg>{t('quizes.error', { error })}</ErrorMsg>;
    }
    if (isFetching && quizzes.length === 0) {
      return <Loader />;
    }
    return <QuizSelectionTable />;
  };

  const { handleCopyLink, onShareSuccessfulyToast } = useShareLink({
    contentType: ContentType.QUIZ,
    shareablePath,
    subject: name,
  });

  const filterOutGuestsFromViewsTableUser = useFilterOutGuestsFromViewsTableUser();

  return (
    <MainScreen
      headline={t('quizes.headline')}
      headerEnd={
        <Hidden mdUp>
          <ShareButtonAndManager
            contentId={id}
            subject={name}
            type='quiz'
            shareablePath={shareablePath}
            handleCopyLink={handleCopyLink}
            onShareSuccessfuly={onShareSuccessfulyToast}
            usersCount={filterOutGuestsFromViewsTableUser(quizViewsByUser).length}
          />
        </Hidden>
      }
    >
      <MainScreenCard>
        <Switch>
          <Route path='/quizzes/:id'>
            <QuizScreen />
          </Route>
          <Route path='/quizzes'>
            <h1>{t('quizes.selectQuiz')}</h1>
            {renderTable()}
          </Route>
        </Switch>
      </MainScreenCard>
    </MainScreen>
  );
}

export default QuizzesScreen;
