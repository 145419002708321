import { all, put, select, takeLatest } from 'redux-saga/effects';

import { ILeanBite } from '../../types/bite';
import { selectOrg } from '../org/org.slice';

import * as bitesCalls from './bites.api';
import { fetchBites, fetchBitesError, fetchFullBitesSuccess, fetchLeanBitesSuccess } from './bites.slice';

export default function* bitesSaga() {
  yield all([takeLatest(fetchBites, fetchBitesSaga)]);
}

function* fetchBitesSaga() {
  try {
    const leanBites: ILeanBite[] = yield fetchLeanBites();
    yield put(fetchLeanBitesSuccess(leanBites));
    yield put(fetchFullBitesSuccess(leanBites));
  } catch (error) {
    yield put(fetchBitesError(error.message));
  }
}

function* fetchLeanBites() {
  const { id: orgId } = yield select(selectOrg);
  const { data: leanBites } = yield bitesCalls.getBites(orgId);
  return leanBites;
}
