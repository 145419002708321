import { format } from 'date-fns';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import Loader from '../../../../components/Loader';
import ReactDataGridUserTable from '../../../../components/ReactDataGrid/UserTable';
import ShareFromTableManager from '../../../../components/Share/ShareFromTableManager';
import gtmTrack from '../../../../services/googleTagManager/track';
import { formatDate } from '../../../../utils/dates';
import { getSendIconDisabledMessage } from '../../../../utils/share';
import { selectOrg } from '../../../org/org.slice';
import { getQuizAnswers } from '../../quiz.api';
import {
  toggleQuizViewsByUserColumnVisibility,
  setQuizViewsByUserFilterValue,
  setQuizViewsByUserGrouping,
  selectQuiz,
} from '../../quiz.slice';

import useMakeFooter from './useMakeFooter';
import useRawColumns from './useRawColumns';
import useRawData from './useRawData';

function QuizUserViewTable() {
  const dispatch = useDispatch();
  const { quizViewsByUserColumnsVisibility, quizViewsByUserFilterValue, quizViewsByUserGrouping, id, name, coverUrl } =
    useSelector(selectQuiz);

  const rawData = useRawData();
  const rawColumns = useRawColumns();
  const makeFooterRows = useMakeFooter();
  const [selectedRows, setSelectedRows] = useState({});
  const [isShareFromTableManagerOpen, setIsShareFromTableManagerOpen] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { datas } = useSelector(selectOrg);

  const handleClickSend = useCallback(() => setIsShareFromTableManagerOpen(true), []);

  const handleToggleIsShared = useCallback(() => {
    setIsShared((value) => !value);
  }, []);

  const handleSearch = useCallback((term) => {
    trackSearch(term);
  }, []);

  const handleClose = useCallback(() => setIsShareFromTableManagerOpen(false), []);
  const sendIconDisabledMessage = useMemo(
    () =>
      getSendIconDisabledMessage({
        selectedRows,
      }),
    [selectedRows],
  );

  const handleOnToggleColumnVisibility = useCallback(
    (columnName) => dispatch(toggleQuizViewsByUserColumnVisibility(columnName)),
    [dispatch],
  );

  const handleOnFilterValueChange = useCallback(
    (filterValue) => dispatch(setQuizViewsByUserFilterValue(filterValue)),
    [dispatch],
  );

  const handleOnGroupByChange = useCallback(
    (groupByValue) => dispatch(setQuizViewsByUserGrouping(groupByValue)),
    [dispatch],
  );

  const createExcel = useCallback((quizAnswers, headers) => {
    const rows = quizAnswers.map((data) =>
      headers
        .map((c) => {
          if (c === 'createdAt' || c === 'lastVisit') {
            return formatDate(data[c]);
          }
          // wrap in quotes for escaping (ie. commas)
          let cell = `"${String(data[c]).replace(/"/g, '""')}"`;

          if (cell === '"null"') {
            cell = '';
          }
          return cell;
        })
        .join(','),
    );
    const contents = [headers, ...rows].join('\n');

    const link = document.createElement('a');
    const url = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(contents);
    const date = format(new Date(), 'dd-MM-yyyy');
    const fileName = `Bites-data-${date}.csv`;
    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.position = 'absolute';
    link.style.visibility = 'hidden';

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
    gtmTrack('export_to_excel', { page_title: origin });
  }, []);

  const getExportData = useCallback(
    async (dataSource) => {
      try {
        const usersIds = dataSource.map((user) => user.id);
        setIsLoading(true);
        const { data } = await getQuizAnswers(usersIds, id);
        setIsLoading(false);
        if (data.length === 0) {
          toast.error('This group of users has no available data to display');
          return;
        }
        const quizAnswers = data.map((obj) => {
          const returnedData = {
            'Full name': obj.fullName,
          };
          if (datas.length !== 0) {
            returnedData[datas[0].title] = obj.data1;
            if (datas[1]) {
              returnedData[datas[1].title] = obj.data2;
            }
            if (datas[2]) {
              returnedData[datas[2].title] = obj.data3;
            }
          }
          returnedData['Question'] = obj.question;
          returnedData['Question type'] = obj.questionType;
          returnedData['Answer Text'] = obj.text;
          returnedData['Is correct'] = obj.isCorrect;
          returnedData['Choice'] = obj.choice;
          return returnedData;
        });
        const headers = Object.keys(quizAnswers[0]);

        createExcel(quizAnswers, headers);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [id, setIsLoading, createExcel, datas],
  );

  return (
    <S.Wrapper>
      {isLoading && (
        <S.LoaderContainer>
          <Loader />
        </S.LoaderContainer>
      )}
      <ReactDataGridUserTable
        maxVisibleColumnsOnMobile={3}
        onToggleColumnVisibility={handleOnToggleColumnVisibility}
        columnsVisibility={quizViewsByUserColumnsVisibility}
        rawData={rawData}
        rawColumns={rawColumns}
        filterValue={quizViewsByUserFilterValue}
        onFilterValueChange={handleOnFilterValueChange}
        withCheckbox
        onSelectedRowsChange={setSelectedRows}
        makeFooterRows={makeFooterRows}
        groupBy={quizViewsByUserGrouping}
        origin='quiz_detailed_view'
        onGroupByChange={handleOnGroupByChange}
        isShared={isShared}
        onSend={handleClickSend}
        onSearch={handleSearch}
        getExportData={getExportData}
        sendIconDisabledMessage={sendIconDisabledMessage}
      />
      <ShareFromTableManager
        isOpen={isShareFromTableManagerOpen}
        onClose={handleClose}
        onShare={handleToggleIsShared}
        selectedRows={selectedRows}
        contentId={id}
        entity='quiz'
        shareablePath={`quiz/${id}`}
        shareableTitle={name}
        shareableCoverUrl={coverUrl}
      />
    </S.Wrapper>
  );
}
const S = {
  LoaderContainer: styled.div`
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    justify-content: center;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  `,
  Wrapper: styled.div`
    position: relative;
  `,
};
export default QuizUserViewTable;

const trackSearch = debounce((term) => gtmTrack('playlists_playlist_detailed_view_search', { term }), 1000);
