import { debounce } from 'lodash';
import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactDataGridUserTable from '../../../../components/ReactDataGrid/UserTable';
import ShareFromTableManager from '../../../../components/Share/ShareFromTableManager';
import gtmTrack from '../../../../services/googleTagManager/track';
import { getSendIconDisabledMessage } from '../../../../utils/share';
import {
  setBiteViewsByUserFilterValue,
  toggleBiteViewsColumnVisibility,
  setBiteViewsByUserGrouping,
  selectBite,
} from '../../bite.slice';

import useMakeFooterRows from './useMakeFooterRows';
import useRawColumns from './useRawColumns';

function Table() {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState({});
  const [isShareFromTableManagerOpen, setIsShareFromTableManagerOpen] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const {
    biteViewsByUser,
    biteViewsByUserColumnsVisibility,
    biteViewsByUserFilterValue,
    biteViewsByUserGrouping,
    subject,
    bitePreview: biteShareId,
    coverUrl,
  } = useSelector(selectBite);

  const rawColumns = useRawColumns();
  const makeFooterRows = useMakeFooterRows();

  const handleClickSend = () => setIsShareFromTableManagerOpen(true);

  const shareCallBack = useCallback(() => {
    setIsShared(!isShared);
  }, [isShared]);

  const handleSearch = (term) => {
    trackSearch(term);
  };

  return (
    <>
      <ReactDataGridUserTable
        maxVisibleColumnsOnMobile={3}
        onToggleColumnVisibility={(columnName) => dispatch(toggleBiteViewsColumnVisibility(columnName))}
        columnsVisibility={biteViewsByUserColumnsVisibility}
        rawData={biteViewsByUser}
        rawColumns={rawColumns}
        filterValue={biteViewsByUserFilterValue}
        onFilterValueChange={(filterValue) => dispatch(setBiteViewsByUserFilterValue(filterValue))}
        withCheckbox
        onSelectedRowsChange={setSelectedRows}
        makeFooterRows={makeFooterRows}
        groupBy={biteViewsByUserGrouping}
        onGroupByChange={(groupByValue) => dispatch(setBiteViewsByUserGrouping(groupByValue))}
        isShared={isShared}
        onSend={handleClickSend}
        onSearch={handleSearch}
        origin='bite_views_detailed_view'
        sendIconDisabledMessage={getSendIconDisabledMessage({
          selectedRows,
        })}
      />
      <ShareFromTableManager
        isOpen={isShareFromTableManagerOpen}
        onClose={() => setIsShareFromTableManagerOpen(false)}
        onShare={shareCallBack}
        selectedRows={selectedRows}
        contentId={biteShareId}
        entity='bite'
        shareablePath={`bites/${biteShareId}`}
        shareableTitle={subject}
        shareableCoverUrl={coverUrl}
      />
    </>
  );
}
export default Table;

const trackSearch = debounce((term) => gtmTrack('bites_bite_detailed_view_search', { term }), 1000);
