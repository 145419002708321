import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const ShareTemplateSelection = ({
  setTemplate,
  template,
  options,
}: {
  setTemplate: React.Dispatch<any>;
  template: string;
  options: any;
}) => {
  const { t } = useTranslation();

  if (!options) {
    return null;
  }

  const formattedOptions = options.map((option: any) => ({
    value: option.id,
    label: option.name,
  }));

  return (
    <>
      <div>{t('share.selectTemplate')}</div>
      <Select defaultValue={template} onChange={setTemplate} options={formattedOptions} />
    </>
  );
};

export default ShareTemplateSelection;
