import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Lightbox from '../../../../components/Lightbox';
import ReactDataGridUserTable from '../../../../components/ReactDataGrid/UserTable';
import ShareFromTableManager from '../../../../components/Share/ShareFromTableManager';
import { formatDate } from '../../../../utils/dates';
import { getSendIconDisabledMessage } from '../../../../utils/share';
import {
  setAnswerAnalysisByUserFilterValue,
  toggleBiteAnswerAnalysisColumnVisibility,
  setAnswerAnalysisByUserGrouping,
  selectBite,
} from '../../bite.slice';

import useMakeFooterRows from './useMakeFooterRows';
import useRawColumns from './useRawColumns';

function BiteAnswerAnalysisTable() {
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [isShareFromTableManagerOpen, setIsShareFromTableManagerOpen] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const dispatch = useDispatch();
  const {
    answerAnalysisByUser,
    answerAnalysisByUserColumnsVisibility,
    answerAnalysisByUserFilterValue,
    answerAnalysisByUserGrouping,
    subject,
    bitePreview: biteShareId,
    questionType,
    coverUrl,
  } = useSelector(selectBite);

  const formattedUsers = useMemo(() => {
    return answerAnalysisByUser.map((u) => ({
      ...u,
      answered: !!u.answer || !!u.imageUrl,
    }));
  }, [answerAnalysisByUser]);

  const formattedUsersFiltered =
    questionType === 'open ended' ? formattedUsers : formattedUsers?.filter((answer) => answer.id !== 1);

  const handleImageClick = (rowIndex: number): void => {
    setLightboxIndex(rowIndex);
    setIsLightboxOpen(true);
  };
  const rawColumns = useRawColumns({
    onImageClick: handleImageClick,
  });
  const makeFooterRows = useMakeFooterRows();
  const handleClickSend = () => setIsShareFromTableManagerOpen(true);

  const shareCallBack = useCallback(() => {
    setIsShared(!isShared);
  }, [isShared]);

  return (
    <>
      <ReactDataGridUserTable
        maxVisibleColumnsOnMobile={3}
        onToggleColumnVisibility={(columnName) => dispatch(toggleBiteAnswerAnalysisColumnVisibility(columnName))}
        columnsVisibility={answerAnalysisByUserColumnsVisibility}
        rawData={formattedUsersFiltered}
        rawColumns={rawColumns}
        filterValue={answerAnalysisByUserFilterValue}
        onFilterValueChange={(filterValue) => dispatch(setAnswerAnalysisByUserFilterValue(filterValue))}
        makeFooterRows={makeFooterRows}
        groupBy={answerAnalysisByUserGrouping}
        withCheckbox
        onSelectedRowsChange={setSelectedRows}
        isShared={isShared}
        onSend={handleClickSend}
        origin='bite_answers_detailed_view'
        sendIconDisabledMessage={getSendIconDisabledMessage({
          selectedRows,
        })}
        onGroupByChange={(groupByValue) => dispatch(setAnswerAnalysisByUserGrouping(groupByValue))}
      />
      {formattedUsersFiltered[lightboxIndex] && (
        <Lightbox
          isOpen={isLightboxOpen}
          imgSrc={formattedUsersFiltered[lightboxIndex].imageUrl}
          onNext={() => {
            let i = lightboxIndex;
            if (i === formattedUsersFiltered.length - 1) {
              i = -1;
            }
            for (i = i + 1; i < Infinity; i++) {
              const user = formattedUsersFiltered[i];
              if (user.imageUrl || user.answer) {
                return setLightboxIndex(i);
              }
              if (i === formattedUsersFiltered.length - 1) {
                i = -1;
              }
            }
          }}
          onPrev={() => {
            let i = lightboxIndex;
            if (i === 0) {
              i = formattedUsersFiltered.length;
            }
            // eslint-disable-next-line for-direction
            for (i = i - 1; i < Infinity; i--) {
              const user = formattedUsersFiltered[i];
              if (user.imageUrl || user.answer) {
                return setLightboxIndex(i);
              }
              if (i === 0) {
                i = formattedUsersFiltered.length - 1;
              }
            }
          }}
          onClose={() => setIsLightboxOpen(false)}
          title={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>{formattedUsersFiltered[lightboxIndex].name}</div>
              <div>{formatDate(formattedUsersFiltered[lightboxIndex].createdAt)}</div>
            </div>
          }
          caption={formattedUsersFiltered[lightboxIndex].answer}
        />
      )}
      <ShareFromTableManager
        isOpen={isShareFromTableManagerOpen}
        onClose={() => setIsShareFromTableManagerOpen(false)}
        onShare={shareCallBack}
        selectedRows={selectedRows}
        contentId={biteShareId}
        entity='bite'
        shareablePath={`bites/${biteShareId}`}
        shareableTitle={subject}
        shareableCoverUrl={coverUrl}
      />
    </>
  );
}

export default BiteAnswerAnalysisTable;
