import { Hidden, makeStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import Loader from './components/Loader';
import Sidebar from './components/Sidebar/Sidebar';
import Topbar from './components/Topbar';
import { TOPBAR_HEIGHT } from './constants/design.constant';
import { geolocationSelector, translationsAreInitializedSelector } from './features/appActivity/appActivity.selectors';
import { requestGeolocation, runInit } from './features/appActivity/appActivity.slice';
import Auth from './features/auth/Auth';
import { currentUserSelector } from './features/auth/auth.selectors';
import useAuthLocationCheck from './features/auth/hooks/useAuthLocationCheck';
import { isProfileLoadingSelector } from './features/authForm/authForm.selectors';
import BitesScreen from './features/bites/Bites.screen';
import OverviewScreen from './features/overview/Overview.screen';
import PlaylistsScreen from './features/playlists/Playlists.screen';
import QuizzesScreen from './features/quizzes/Quizzes.screen';
import UserAnalysisScreen from './features/userAnalysis/userAnalysis.screen';
import UserManagementScreen from './features/userManagement/UserManagement.screen';
import useInitApp from './hooks/useInitApp';

export const AUTH_PATHS = ['/login', '/auth/password-recovery/create-new-password'];

function App() {
  const dispatch = useDispatch();

  const classes = useStyles();
  const translationsAreInitialized = useSelector(translationsAreInitializedSelector);
  const geolocation = useSelector(geolocationSelector);
  const isProfileLoading = useSelector(isProfileLoadingSelector);
  const user = useSelector(currentUserSelector);

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const isPreview = query.get('isPreview');

  useAuthLocationCheck();

  const isInitDone = useInitApp();
  useEffect(() => {
    if (!geolocation) {
      dispatch(requestGeolocation());
    }
  }, [dispatch, geolocation]);

  useEffect(() => {
    dispatch(runInit());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isInitDone || !translationsAreInitialized) {
    return <Loader />;
  }

  return (
    <Switch>
      <Route path={AUTH_PATHS}>
        <Auth />
      </Route>
      <Route path='/'>
        <div style={{ height: '100%' }}>
          {isProfileLoading || !user ? (
            <Loader />
          ) : (
            <>
              <Topbar onOpenSidebar={() => setIsSideBarOpen(true)} />
              <div className={classes.sidebarAndMainRoot}>
                <Hidden mdUp>
                  <Drawer
                    anchor='left'
                    open={isSideBarOpen}
                    onClose={() => setIsSideBarOpen(false)}
                    variant='temporary'
                  >
                    <Sidebar onCloseSidebar={() => setIsSideBarOpen(false)} />
                  </Drawer>
                </Hidden>
                <Hidden smDown>
                  <Sidebar onCloseSidebar={() => setIsSideBarOpen(false)} />
                </Hidden>
                <section className={classes.mainSection}>
                  <Switch>
                    <Route path='/overview'>
                      <OverviewScreen />
                    </Route>

                    <Route path='/bites'>
                      <BitesScreen />
                    </Route>
                    <Route path='/playlists'>
                      <PlaylistsScreen />
                    </Route>
                    <Route path='/quizzes'>
                      <QuizzesScreen />
                    </Route>
                    <Route path='/user-management'>
                      <UserManagementScreen />
                    </Route>
                    <Route path='/user-analysis'>
                      <UserAnalysisScreen />
                    </Route>
                    <Route path='/'>
                      <Redirect to={isPreview ? `/overview/?isPreview=${isPreview}` : '/overview'} />
                    </Route>
                  </Switch>
                </section>
              </div>
            </>
          )}
        </div>
      </Route>
    </Switch>
  );
}

const useStyles = makeStyles((theme) => ({
  sidebarAndMainRoot: {
    display: 'flex',
    minHeight: `calc(100vh - ${TOPBAR_HEIGHT}px)`,
  },
  mainSection: {
    minHeight: `calc(100vh - ${TOPBAR_HEIGHT}px)`,
    backgroundColor: '#EDEDF2',
    height: '100%',
    overflow: 'auto',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
}));

export default App;
