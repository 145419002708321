import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { percentage } from '../../../../utils/numbers';
import { sort } from '../../../../utils/reactDataGrid';
import { selectBite } from '../../bite.slice';

interface IArgs {
  groupedByDataIndex: number;
}

const useDataSource = ({ groupedByDataIndex }: IArgs) => {
  const { t } = useTranslation();
  const { biteViewsByUser, biteViewsByUserSortInfo } = useSelector(selectBite);

  return useMemo(() => {
    const result = biteViewsByUser
      .reduce((acc, user) => {
        const dataGroupName = user.datas[groupedByDataIndex] || t('bites.empty');
        const existingGroup = acc.find((item) => item.name === dataGroupName);
        if (!existingGroup) {
          acc.push({
            name: dataGroupName,
            viewed: Number(user.viewed),
            recordsCount: 1,
          });
        } else {
          existingGroup.viewed += Number(user.viewed);
          existingGroup.recordsCount += 1;
        }
        return acc;
      }, [])
      .map((item) => ({
        ...item,
        viewedPercentage: percentage(item.viewed, item.recordsCount),
      }));
    return sort(result, biteViewsByUserSortInfo);
  }, [t, biteViewsByUser, groupedByDataIndex, biteViewsByUserSortInfo]);
};

export default useDataSource;
