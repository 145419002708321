import { createSelector, createSlice } from '@reduxjs/toolkit';

import { IAnswer } from '../../types/answer';
import { IBiteShare } from '../../types/biteShare';
import { IBiteAnswersTableUser, IBiteViewsTableUser } from '../../types/biteTableUser';
import { IComment } from '../../types/comment';
import { IQuestionType } from '../../types/question';
import {
  IRdgColumnVisibility,
  IRdgFilters,
  IRdgGrouping,
  IRdgSortInfo,
  ITableDisplay,
} from '../../types/reactDataGrid';
import { IRootState } from '../../types/store';
import {
  initColumnVisibility,
  initFilterValue,
  initGrouping,
  mapSelectFilter,
  mapStringFilter,
} from '../../utils/reactDataGrid';
import { formatDatas, isMobile } from '../../utils/utils';
import { selectBites } from '../bites/bites.slice';

const biteSlice = createSlice({
  name: 'bite',
  initialState: getInitialState(),
  extraReducers: getExtraReducers(),
  reducers: getReducers(),
});

export const {
  fetchBiteOverview,
  fetchBiteOverviewSuccess,
  fetchBiteOverviewError,
  fetchComments,
  fetchCommentsSuccess,
  fetchCommentsError,
  fetchAnswerAnalysis,
  fetchAnswerAnalysisSuccess,
  fetchAnswerAnalysisError,
  fetchBiteViews,
  fetchBiteViewsSuccess,
  fetchBiteViewsError,
  toggleBiteViewsColumnVisibility,
  toggleBiteAnswerAnalysisColumnVisibility,
  setBiteViewsByUserFilterValue,
  setAnswerAnalysisByUserFilterValue,
  setBiteViewsByUserGrouping,
  setAnswerAnalysisByUserGrouping,
  setBiteViewsByUserTableDisplay,
  setAnswerAnalysisTableDisplay,
  setBiteViewsByUserSortInfo,
  setAnswerAnalysisByUserSortInfo,
  fetchDeleteComment,
  fetchDeleteCommentSuccess,
  fetchDeleteCommentError,
} = biteSlice.actions;

export default biteSlice.reducer;

export const selectBite = createSelector([(state: IRootState) => state.bite, selectBites], (bite, bites) => {
  const matchingBite = bites.bites.find((b) => b.id === bite.id);

  return {
    ...bite,
    coverUrl: matchingBite ? matchingBite.coverUrl : undefined,
  };
});

function getInitialState() {
  return {
    comments: [] as IComment[],
    commentsCount: 0,
    createdBy: '',
    id: -1,
    isFetchingComments: false,
    isFetchingBiteViews: false,
    isFetchingOverview: false,
    isFetchingAnswerAnalysis: false,
    overviewError: '',
    commentsError: '',
    answerAnalysisError: '',
    viewsError: '',
    discussionEnabled: false,
    biteShares: [] as IBiteShare[],
    answers: [] as IAnswer[],
    questionType: '' as IQuestionType,
    question: '',
    subject: '',
    description: '',
    ownerName: '',
    bitePreview: -1,
    watched: 0,
    coverUrl: undefined,
    ...getBiteViewsByUserInitialState(),
    ...getAnswerAnalysisByUserInitialState(),
  };
}

type IBiteState = ReturnType<typeof getInitialState>;

function getExtraReducers() {
  return {
    'auth/logout': () => getInitialState(),
    'org/fetchOrgSuccess': (state: IBiteState, action) => {
      const datas = formatDatas(action.payload);
      if (datas.length === 0) {
        state.biteViewsByUserTableDisplay = 'drilldown';
        state.answerAnalysisTableDisplay = 'drilldown';
      }
      initColumnVisibility('biteViewsByUserColumnsVisibility', isMobile() ? 0 : 2)(state, datas);
      initColumnVisibility('answerAnalysisByUserColumnsVisibility', isMobile() ? 0 : 2)(state, datas);
      initFilterValue('biteViewsByUserFilterValue')(state, datas);
      initFilterValue('answerAnalysisByUserFilterValue')(state, datas);
      initGrouping('biteViewsByUserGrouping')(state, datas);
      initGrouping('answerAnalysisByUserGrouping')(state, datas);
    },
  };
}

function getReducers() {
  return {
    fetchBiteOverview: (state: IBiteState) => {
      state.isFetchingOverview = true;
      state.overviewError = '';
    },
    fetchBiteOverviewSuccess: (state: IBiteState, action) => {
      const {
        id,
        subject,
        description,
        ownerName,
        biteShares,
        question,
        questionType,
        answers,
        discussionEnabled,
        bitePreview,
        commentsCount,
        watched,
        coverUrl,
      } = action.payload;
      state.isFetchingOverview = false;
      state.subject = subject;
      state.description = description;
      state.createdBy = ownerName;
      state.biteShares = biteShares;
      state.question = question;
      state.questionType = questionType;
      state.answers = answers;
      state.bitePreview = bitePreview;
      state.commentsCount = commentsCount;
      state.watched = watched;
      state.discussionEnabled = discussionEnabled;
      state.id = id;
      state.answerAnalysisByUserColumnsVisibility.answer = questionType === 'open ended' && !isMobile();
      state.coverUrl = coverUrl;
    },
    fetchBiteOverviewError: (state: IBiteState, action) => {
      state.isFetchingOverview = false;
      state.overviewError = action.payload;
    },
    fetchComments: (state: IBiteState) => {
      state.isFetchingComments = true;
      state.comments = [];
      state.commentsError = '';
    },
    fetchCommentsSuccess: (state: IBiteState, action) => {
      state.isFetchingComments = false;
      state.comments = action.payload;
    },
    fetchCommentsError: (state: IBiteState, action) => {
      state.isFetchingComments = false;
      state.commentsError = action.payload;
    },
    fetchAnswerAnalysis: (state: IBiteState) => {
      state.isFetchingAnswerAnalysis = true;
      state.answerAnalysisError = '';
      state.answerAnalysisByUser = [];
    },
    fetchAnswerAnalysisSuccess: (state: IBiteState, action) => {
      state.isFetchingAnswerAnalysis = false;
      state.answerAnalysisByUser = action.payload;
    },
    fetchAnswerAnalysisError: (state: IBiteState, action) => {
      state.isFetchingAnswerAnalysis = false;
      state.answerAnalysisError = action.payload;
    },
    fetchBiteViews: (state: IBiteState) => {
      state.isFetchingBiteViews = true;
      state.biteViewsByUser = [];
      state.viewsError = '';
    },
    fetchBiteViewsSuccess: (state: IBiteState, action) => {
      state.isFetchingBiteViews = false;
      state.biteViewsByUser = action.payload.map((user) => ({
        ...user,
        // convert empty datas from null to empty strings to
        // avoid syncfusion grid from crashing
        datas: user.datas.map((data) => data || ''),
      }));
    },
    fetchBiteViewsError: (state: IBiteState, action) => {
      state.isFetchingBiteViews = false;
      state.viewsError = action.payload;
    },
    toggleBiteViewsColumnVisibility: (state: IBiteState, action) => {
      state.biteViewsByUserColumnsVisibility[action.payload] = !state.biteViewsByUserColumnsVisibility[action.payload];
    },
    toggleBiteAnswerAnalysisColumnVisibility: (state: IBiteState, action) => {
      state.answerAnalysisByUserColumnsVisibility[action.payload] =
        !state.answerAnalysisByUserColumnsVisibility[action.payload];
    },
    setBiteViewsByUserFilterValue: (state: IBiteState, action) => {
      state.biteViewsByUserFilterValue = action.payload;
    },
    setAnswerAnalysisByUserFilterValue: (state: IBiteState, action) => {
      state.answerAnalysisByUserFilterValue = action.payload;
    },
    setBiteViewsByUserGrouping: (state: IBiteState, action) => {
      state.biteViewsByUserGrouping = action.payload;
    },
    setAnswerAnalysisByUserGrouping: (state: IBiteState, action) => {
      state.answerAnalysisByUserGrouping = action.payload;
    },
    setBiteViewsByUserTableDisplay: (state: IBiteState, action) => {
      state.biteViewsByUserTableDisplay = action.payload;
    },
    setAnswerAnalysisTableDisplay: (state: IBiteState, action) => {
      state.answerAnalysisTableDisplay = action.payload;
    },
    setBiteViewsByUserSortInfo: (state: IBiteState, action) => {
      state.biteViewsByUserSortInfo = action.payload;
    },
    setAnswerAnalysisByUserSortInfo: (state: IBiteState, action) => {
      state.answerAnalysisByUserSortInfo = action.payload;
    },
    fetchDeleteComment: (state: IBiteState) => {
      state.isFetchingComments = true;
    },
    fetchDeleteCommentSuccess: (state: IBiteState, action) => {
      const commentId = action.payload;
      state.comments = state.comments.filter((c) => c.id !== commentId);
      state.comments.forEach((element) => {
        element.relatedComments = element.relatedComments.filter((c) => c.id !== commentId);
      });
      state.isFetchingComments = false;
    },
    fetchDeleteCommentError: (state: IBiteState, action) => {
      state.isFetchingComments = false;
      state.commentsError = action.payload;
    },
  };
}

function getBiteViewsByUserInitialState() {
  return {
    biteViewsByUser: [] as IBiteViewsTableUser[],
    biteViewsByUserColumnsVisibility: {
      name: true,
      viewed: true,
      phone: false,
      email: false,
      lastVisit: false,
      employeeId: false,
    } as IRdgColumnVisibility,
    biteViewsByUserFilterValue: [
      ...['name'].map(mapStringFilter),
      {
        name: 'viewed',
        operator: 'eq',
        type: 'select',
        value: true,
      },
    ] as IRdgFilters,
    biteViewsByUserGrouping: [] as IRdgGrouping,
    biteViewsByUserTableDisplay: 'data1' as ITableDisplay,
    biteViewsByUserSortInfo: {
      name: 'viewed',
      type: 'number',
      dir: -1,
    } as IRdgSortInfo,
  };
}

function getAnswerAnalysisByUserInitialState() {
  return {
    answerAnalysisByUser: [] as IBiteAnswersTableUser[],
    answerAnalysisByUserColumnsVisibility: {
      name: true,
      answeredCorrectly: true,
      answer: !isMobile(),
      imageUrl: !isMobile(),
      answered: !isMobile(),
      employeeId: false,
      lastVisit: false,
      phone: false,
      email: false,
    } as IRdgColumnVisibility,
    answerAnalysisByUserFilterValue: [
      ...['name', 'answer'].map(mapStringFilter),
      ...['answered', 'answeredCorrectly'].map(mapSelectFilter),
    ] as IRdgFilters,
    answerAnalysisByUserGrouping: [] as IRdgGrouping,
    answerAnalysisTableDisplay: 'data1' as ITableDisplay,
    answerAnalysisByUserSortInfo: {
      name: 'answeredPercentage',
      type: 'number',
      dir: -1,
    } as IRdgSortInfo,
  };
}
