import capitalize from 'lodash/capitalize';
import size from 'lodash/size';
import moment from 'moment';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  getSmsTemplates,
  getWhatsAppTemplates,
  scheduleDistributionAllUsers,
  scheduleDistributionUnviewedUsers,
  shareWithIntegration,
  TShareTemplates,
} from '../../app/integration.api';
import { BEEKEEPER_DEFAULT_MESSAGE_TEXT } from '../../constants/constants';
import { fetchTeams, selectOrg } from '../../features/org/org.slice';
import { IEntityType } from '../../types/entity';
import { EIntegrations, IIntegrationType, TAllowedDMShareIntegrations } from '../../types/integration';

import ChooseIntegrationModal from './ChooseIntegrationModal';
import { useDistributionReminderDealy } from './hooks/useDistributionReminderDelay';
import ShareWithDMModal from './ShareWithDMModal';
import {
  IOnShareWithBeekeeperArgs,
  IOnShareWithSMSArgs,
  IOnShareWithWhatsappArgs,
} from './ShareWithDMModal/ShareWithDMModal';
import ShareWithTeamsModal from './ShareWithTeamsModal/ShareWithTeamsModal';
import { TShareWithDMConfig } from './types';
import useMailMerge from './useMailMerge';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onShare: () => void;
  selectedRows: Record<any, number>;
  entity: IEntityType;
  shareablePath: string;
  shareableTitle: string;
  contentId: number;
  shareableCoverUrl?: string;
}

const ShareFromTableManager: React.FC<IProps> = ({
  isOpen: isChooseIntegrationModalOpen,
  onClose: closeChooseIntegrationModal,
  onShare,
  selectedRows,
  entity,
  shareablePath,
  shareableTitle,
  contentId,
  shareableCoverUrl,
}) => {
  const { t } = useTranslation();
  const { id } = useSelector(selectOrg);
  const dispatch = useDispatch();

  const { handleShareMailMerge } = useMailMerge({ itemId: contentId });
  const [isMailMergeLoading, setIsMailMergeLoading] = useState(false);
  const [isShareWithTeamsModalOpen, setIsShareWithTeamsModalOpen] = useState(false);
  const [integration, setIntegration] = useState<IIntegrationType | null>(null);
  const [modelTemplate, setModelTemplate] = useState<undefined | { sms: TShareTemplates; whatsapp: TShareTemplates }>();
  const [isShareWithIntegrationOpen, setIsShareWithIntegrationOpen] = useState(false);
  const org = useSelector(selectOrg);
  const { delay } = useDistributionReminderDealy({ integration });

  const onCloseModal = useCallback(() => {
    setIsShareWithIntegrationOpen(false);
    setIntegration(null);
  }, []);

  const handleShareWithDM = useCallback(
    async (args: IOnShareWithSMSArgs | IOnShareWithWhatsappArgs | IOnShareWithBeekeeperArgs) => {
      const { sendLater, sendLaterDateTime, sendReminder, setIsSendButtonDisabled, integrationType, message } = args;

      const payload = {
        users: Object.keys(selectedRows),
        template_id: args.templateId,
        integration: integrationType,
        biteShare: entity === 'bite' ? contentId : undefined,
        playlist: entity === 'playlist' || entity === 'quiz' ? contentId : undefined,
        customReplyMessage: message,
      };

      let shareSucceeded = false;
      try {
        setIsSendButtonDisabled(true);
        if (sendLater) {
          if (moment().isAfter(sendLaterDateTime)) {
            throw new Error('Scheduled time must be in the future!');
          }
          const sendAt = sendLaterDateTime.toISOString();
          await scheduleDistributionAllUsers({ sendAt, distParams: payload });
          toast(t('share.noStatsModal.successfullyScheduled', { entity, time: sendLaterDateTime.fromNow() }));
        } else {
          await shareWithIntegration(payload);
          toast(t('share.noStatsModal.sharedSuccessfully', { entity: capitalize(entity) }));
        }

        closeChooseIntegrationModal();
        onShare();
        onCloseModal();
        shareSucceeded = true;
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsSendButtonDisabled(false);
      }

      if (shareSucceeded && sendReminder) {
        try {
          const initialMsgTime = sendLater ? sendLaterDateTime : moment();
          const reminderTime = initialMsgTime.add(delay, 'hours');
          await scheduleDistributionUnviewedUsers({ sendAt: reminderTime.toISOString(), distParams: payload });
          toast(t('share.noStatsModal.reminder', { time: capitalize(entity) }));
        } catch (error) {
          toast.error(error.message);
        }
      }
    },
    [selectedRows, entity, contentId, closeChooseIntegrationModal, onShare, onCloseModal, t, delay],
  );

  const handleChooseOption = useCallback(
    async (newIntegration: IIntegrationType) => {
      setIntegration(newIntegration);

      if (
        [EIntegrations.SMS, EIntegrations.WhatsApp, EIntegrations.Email, EIntegrations.Beekeeper].includes(
          newIntegration,
        )
      ) {
        closeChooseIntegrationModal();
        setIsShareWithIntegrationOpen(true);
      } else if (newIntegration === EIntegrations.Teams) {
        setIsShareWithTeamsModalOpen(true);
        dispatch(fetchTeams({ orgId: id }));
      } else if (newIntegration === EIntegrations.Mail_merge) {
        handleShareMailMerge({
          biteShare: entity === 'bite' ? contentId : undefined,
          playlist: entity === 'playlist' || entity === 'quiz' ? contentId : undefined,
          users: Object.keys(selectedRows),
          onLoadingChange: setIsMailMergeLoading,
          onSuccess: closeChooseIntegrationModal,
        });
      }
    },
    [dispatch, id, contentId, entity, selectedRows, closeChooseIntegrationModal, handleShareMailMerge],
  );

  const handleCloseTeamsModal = useCallback(() => {
    setIsShareWithTeamsModalOpen(false);
  }, []);

  const getUsersCountText = useMemo(() => {
    return t('share.noStatsModal.withUsers', { entity: capitalize(entity), length: size(selectedRows) });
  }, [t, entity, selectedRows]);

  useEffect(() => {
    const fetchTemplatesData = async () => {
      try {
        let smsData: TShareTemplates = [];
        let whatsappData: TShareTemplates = [];
        const [whatsappRequest, smsRequest] = await Promise.allSettled([
          getWhatsAppTemplates(org.id),
          getSmsTemplates(org.id),
        ]);

        if (whatsappRequest.status === 'fulfilled') {
          whatsappData = whatsappRequest.value.data;
        }

        if (smsRequest.status === 'fulfilled') {
          smsData = smsRequest.value.data;
        }

        setModelTemplate({ whatsapp: whatsappData, sms: smsData });
      } catch (error) {
        console.error('Error fetching templates data:', error);
      }
    };

    fetchTemplatesData();
  }, [org.id]);

  const modalConfigs: TShareWithDMConfig = useMemo(
    () => ({
      [EIntegrations.WhatsApp]: { templates: modelTemplate?.whatsapp },
      [EIntegrations.SMS]: { templates: modelTemplate?.sms },
      [EIntegrations.Email]: { shareableCoverUrl: shareableCoverUrl },
      [EIntegrations.Beekeeper]: { customMessage: BEEKEEPER_DEFAULT_MESSAGE_TEXT },
    }),
    [modelTemplate, shareableCoverUrl],
  );

  return (
    <>
      <ChooseIntegrationModal
        isOpen={isChooseIntegrationModalOpen}
        onClose={closeChooseIntegrationModal}
        entity={entity}
        onChoose={handleChooseOption}
        isLoading={isMailMergeLoading}
      />

      {integration &&
        Object.keys(modalConfigs).map(
          (key: TAllowedDMShareIntegrations) =>
            integration === key && (
              <ShareWithDMModal
                key={key}
                isOpen={isShareWithIntegrationOpen}
                entity={entity}
                integration={integration}
                onClose={onCloseModal}
                sendedUsersOrGroupsText={getUsersCountText}
                shareableTitle={shareableTitle}
                shareablePath={shareablePath}
                onShare={handleShareWithDM}
                templates={modalConfigs[key].templates}
                shareableCoverUrl={modalConfigs[key].shareableCoverUrl}
                customMessage={modalConfigs[key].customMessage}
              />
            ),
        )}

      <ShareWithTeamsModal
        entity={entity}
        isOpen={isShareWithTeamsModalOpen}
        onClose={handleCloseTeamsModal}
        contentId={contentId}
      />
    </>
  );
};

export default ShareFromTableManager;
